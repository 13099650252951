import { createLocalVue } from '@vue/test-utils';
import Vuex from 'vuex';

import { getOriginKey } from '../api/adyenApi';

import adyenStore from './adyenStore';

jest.mock('../api/adyenApi', () => ({
  getOriginKey: jest.fn().mockResolvedValue({
    providers: [{
      name: 'adyen',
      credentials: {
        originKeys: {
          'http://localhost': 'origin-key',
        },
      },
    }],
  }),
}));

const localVue = createLocalVue();
localVue.use(Vuex);

const store = new Vuex.Store(adyenStore);

window.location ??= Object.create({ origin: 'http://localhost' });

store.state.UserData = { userData: { uuid: 'user-uuid' } };

describe('Given adyenStore', () => {
  beforeEach(() => {
    jest.resetModules();
  });

  describe('When then "loadOriginKey" action is dispatched', () => {
    it('Then then "getOriginKey" method is called', async () => {
      await store.dispatch('loadOriginKey');

      expect(getOriginKey).toHaveBeenCalled();
    });

    it('Then then "originKey" values is "origin-key"', async () => {
      await store.dispatch('loadOriginKey');

      expect(store.state.originKey).toBe('origin-key');
    });
  });
});
